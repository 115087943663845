<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        dense
        flat
        outlined
        background-color="white"
        placeholder="search"
        v-model="search"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="journals"
        dense
        @click:row="onClickRow"
        :search="search"
      >
        <template v-slot:item.no="{ item }">
          {{ journals.map(x => x).indexOf(item) + 1 }}
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Title",
        value: "title",
        sortable: false,
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Reference",
        value: "reference",
        sortable: false,
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Date",
        value: "date",
        sortable: false,
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    search: "",
  }),

  computed: {
    ...mapState("journal", ["journals"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("journal/getAll")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Journal", params: { id: item.id } });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
